import styled from 'styled-components';

export const Container = styled.div`
  background: #fb4a20;
  color: #fff;
  width: 100%;
  max-width: 410px;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100px;
    height: 150px;
  }
`;
