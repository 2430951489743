import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import { uuid } from 'uuidv4';

import { useOrder } from '../order/index';

const CartContext = createContext();

const CartProvider = ({ children }) => {
  const { addToOrder } = useOrder();
  const [cart, setCart] = useState(() => {
    const cartStorage = localStorage.getItem('@qrexpress:cart');

    if (cartStorage) {
      return JSON.parse(cartStorage);
    }

    return [];
  });

  useEffect(() => {
    localStorage.setItem('@qrexpress:cart', JSON.stringify(cart));
  }, [cart]);

  const addCartToOrder = useCallback(
    async (payment_method, paytime_token) => {
      await addToOrder(cart, payment_method, paytime_token);
    },
    [cart, addToOrder],
  );

  const closeCart = useCallback(() => {
    localStorage.removeItem('@qrexpress:cart');

    setCart([]);
  }, []);

  const increment = useCallback(
    id => {
      const newCart = [...cart];
      const findCart = newCart.findIndex(item => item.id === id);

      newCart[findCart].amount += 1;

      setCart(newCart);
    },
    [cart],
  );

  const decrement = useCallback(
    id => {
      const newCart = [...cart];
      const findCart = newCart.findIndex(item => item.id === id);

      newCart[findCart].amount -= 1;

      setCart(newCart);
    },
    [cart],
  );

  const addToCart = useCallback(
    basket => {
      setCart([
        ...cart,
        {
          basket_id: uuid(),
          id: basket.product.id,
          name: basket.product.name,
          price: basket.product.price,
          image: basket.product.image,
          amount: basket.amount,
          observation: basket.observation,
          complement_categories: basket.complementsCategory,
        },
      ]);
    },
    [cart],
  );

  const removeFromCart = useCallback(
    id => {
      const newCart = [...cart];
      const productId = newCart.findIndex(item => item.basket_id === id);

      if (productId >= 0) {
        const cartFiltered = newCart.filter(
          product => product.basket_id !== id,
        );

        setCart(cartFiltered);
      } else {
        console.log('Produto nao existe');
      }
    },
    [cart],
  );

  const value = useMemo(
    () => ({
      addToCart,
      decrement,
      increment,
      cart,
      closeCart,
      addCartToOrder,
      removeFromCart,
    }),
    [
      addToCart,
      closeCart,
      decrement,
      increment,
      cart,
      addCartToOrder,
      removeFromCart,
    ],
  );

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

const useCart = () => {
  const context = useContext(CartContext);

  if (!context) {
    console.log('useCart must be within a provider');
  }

  return context;
};

export { CartProvider, useCart };
