import styled, { css } from 'styled-components';

export const Container = styled.div`
  max-width: 410px;
  width: 100%;
  height: 48px;
  display: flex;
  border-radius: 7px;
  border: 1px solid #33333320;
  margin-top: 20px;
  position: relative;
  ${props =>
    props.isFocused &&
    css`
      border-color: #fb4a20;
    `}
  input {
    border: 0;
    background: transparent;
    width: 100%;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #33333390;
    &::placeholder {
      text-align: center;
      color: #33333390;
    }
  }
`;

export const Error = styled.div`
  position: absolute;
  top: -25px;
  left: 5px;
  padding: 8px;
  border-radius: 7px;
  background: #fb4a20;
  &::before {
    content: '';
    border-style: solid;
    border-color: #fb4a20 transparent;
    border-width: 6px 6px 0 6px;
    top: 100%;
    bottom: 20px;
    position: absolute;
  }
  p {
    color: #fff;
    font-size: 10px;
    font-weight: bold;
  }
`;
