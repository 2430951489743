import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useHistory } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import { useAuth } from '../../context/auth';

import OrdersFinished from './OrdersFinished';
import OrdersInProgress from './OrdersInProgress';

import { Container, Content, Header, Main, ButtonOrders, Nav } from './styles';

const Orders = () => {
  const history = useHistory();
  const { restaurantId } = useAuth();

  function handleNavigate() {
    history.push(`/app/menu`);
  }

  return (
    <Container>
      <Content>
        <Header>
          <h1>Meus Pedidos</h1>
        </Header>

        <Main>
          <Tabs>
            <Nav>
              <TabList>
                <Tab>Em andamento</Tab>
                <Tab>Finalizadas</Tab>
              </TabList>
            </Nav>

            <TabPanel>
              <OrdersInProgress />
            </TabPanel>

            <TabPanel>
              <OrdersFinished />
            </TabPanel>
          </Tabs>
        </Main>

        <ButtonOrders onClick={handleNavigate}>
          <FiArrowLeft />
          Voltar para Cardápio
        </ButtonOrders>
      </Content>
    </Container>
  );
};

export default Orders;
