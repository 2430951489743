import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #f8f8f8;
  min-height: 100vh;
  width: 100%;
  max-width: 410px;
`;

export const Content = styled.div`
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 80px;
`;

export const Header = styled.header`
  position: fixed;
  width: 100%;
  max-width: 410px;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  -webkit-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);

  h1 {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const ButtonGroup = styled.div`
  width: 100%;
  max-width: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`;

export const ButtonDeliveryType = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 80px;
  background: #fff;
  box-shadow: 0 0 15px #33333320;
  border: 0;
  border-radius: 5px;

  & + div {
    margin-top: 16px;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 26px;
  }

  p {
    width: 100%;
    font-weight: bold;
    text-align: center;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const Title = styled.p`
  margin-top: 86px;
`;

export const DeliveryTime = styled.div`
  display: flex;
  margin-top: 8px;
  align-items: center;

  p {
    margin-left: 4px;
    font-weight: normal;
  }
`;
