import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../context/auth';
import { useCart } from '../../context/cart';

import { Container, Content, Message, PaymentLink, Button, ConfirmPixButton, PixArea,
ModalBody, ModalFooter, ModalTitle, ConfirmButton, HelpPixButton } from './styles';
import logopix from '../../assets/images/logopix.png';


import logoPicpay from '../../assets/images/picpay-logo_BR.png';
import api from '../../services/api';

import faltaPouco from '../../assets/images/faltaPouco.svg';
import Modal from 'react-modal';

const AlmostThere = () => {
  const {
    picpayReferenceId,
    userPaymentMethod,
    isSmsServiceOptional,
    userChosePaySms,
    restaurantHasSmsService,
    userDeliveryType,
    pixCodePayment
  } = useAuth();

  const { closeCart } = useCart();

  const history = useHistory();
  const [buttonText, setButtonText] = useState('Já realizei o pagamento');
  const [buttonCopyText, setButtonCopyText] = useState('Copiar Código Pix');
  const [modalIsOpen, setIsOpen] = useState(userPaymentMethod.name.toString() === 'pix_online' ? true : false);

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  async function handleNavigate() {
    if(userPaymentMethod.name.toString() === 'pix_online'){
      try{
        const response = await api.post(
          'client/validate-pix',
          {
            pix_code: pixCodePayment
          },
        );

        if(response.data.paid){
          closeCart();
          history.push(`/app/orders`);
        }else{
          setButtonText('Pagamento não confirmado. Tente novamente!')
        }
      }catch{
        alert('Erro rota validar pix');
      }
    }else{
      closeCart();
      history.push(`/app/orders`);
    }
  }


  return (
    <Container>
      <Content>
        <Message>
        {userPaymentMethod.name.toString() !== 'pix_online' ?
          <>
            <img
              src={faltaPouco}
              alt="Ilustração com pessoa segurando o celular"
            />
            <h1>Agora falta pouco</h1>
          </>
          :
          <>
            <img
              src={logopix}
              alt="Pix"
              style={{width: 150}}
            />
            <h3>Finalize o pagamento via Pix!</h3>
          </>
        }
          {(() => {
            if (restaurantHasSmsService.toString() === 'true') {
              if (
                (isSmsServiceOptional.toString() === 'true' &&
                  userChosePaySms.toString() === 'true') ||
                (restaurantHasSmsService.toString() === 'true' &&
                  isSmsServiceOptional.toString() === 'false')
              ) {
                return (
                  <>
                    {userPaymentMethod.name.toString() === 'picpay' &&
                      (userDeliveryType.toString() === 'withdrawal' ||
                        !userDeliveryType) && (
                        <p>
                          Assim que o pagamento for realizado e confirmado, o
                          seu pedido irá para preparo. Caso ainda não o tenha
                          efetuado, basta clicar no botão PicPay abaixo.
                          Acompanhe o status na opção Meus Pedidos. Você
                          receberá um sms assim que seu pedido ficar pronto para
                          retirada.
                        </p>
                      )}
                  {userPaymentMethod.name.toString() === 'pix_online' &&
                  (userDeliveryType.toString() === 'withdrawal' ||
                    !userDeliveryType) && (
                      <PixArea>
                        <p>Código Pix Copia e Cola</p>
                        <input value={pixCodePayment}></input>
                        <ConfirmPixButton onClick={() =>  {navigator.clipboard.writeText(pixCodePayment); setButtonCopyText('Código Copiado!')}}>
                          {buttonCopyText}
                        </ConfirmPixButton>
                        <HelpPixButton style={{marginTop: 5}} onClick={() => {setIsOpen(true)}}>Como pagar com Pix</HelpPixButton>
                        {/* <p>Copie o código PIX acima, abra o app do seu banco preferido, vá na área PIX e cole o código PIX para efetuar o pagamento!</p> */}
                        <p><strong>O Código expira em 5 minutos!</strong></p>
                      </PixArea>
                    )}

                    {userPaymentMethod.type.toString() === 'online' &&
                      userPaymentMethod.name.toString() === 'credito' &&
                      (userDeliveryType.toString() === 'withdrawal' ||
                        !userDeliveryType) && (
                        <p>
                          Assim que o pagamento for realizado e confirmado, o
                          seu pedido irá para preparo. Acompanhe o status na
                          opção
                          <strong> Meus Pedidos</strong>. Você receberá um sms
                          assim que seu pedido ficar pronto para retirada.
                        </p>
                      )}

                    {userPaymentMethod.type.toString() === 'online' &&
                      userPaymentMethod.name.toString() === 'credito' &&
                      userDeliveryType.toString() === 'delivery' && (
                        <p>
                          Assim que o pagamento for realizado e confirmado, o
                          seu pedido irá para preparo. Acompanhe o status na
                          opção
                          <strong> Meus Pedidos</strong>. Você receberá um sms
                          assim que seu pedido sair para entrega.
                        </p>
                      )}

                    {userPaymentMethod.type.toString() === 'online' &&
                      userPaymentMethod.name.toString() === 'picpay' &&
                      userDeliveryType.toString() === 'delivery' && (
                        <p>
                          Assim que o pagamento for realizado e confirmado, o
                          seu pedido irá para preparo. Caso ainda não o tenha
                          efetuado, basta clicar no botão PicPay abaixo.
                          Acompanhe o status na opção Meus Pedidos. Você
                          receberá um sms assim que seu pedido sair para
                          entrega.
                        </p>
                      )}

                    {userPaymentMethod.type.toString() === 'online' &&
                      userPaymentMethod.name.toString() === 'pix_online' &&
                      userDeliveryType.toString() === 'delivery' && (
                      <PixArea>
                        <p>Código Pix Copia e Cola</p>
                        <input value={pixCodePayment}></input>
                        <ConfirmPixButton onClick={() =>  {navigator.clipboard.writeText(pixCodePayment); setButtonCopyText('Código Copiado!')}}>
                          {buttonCopyText}
                        </ConfirmPixButton>
                        <HelpPixButton style={{marginTop: 5}} onClick={() => {setIsOpen(true)}}>Como pagar com Pix</HelpPixButton>
                        {/* <p>Copie o código PIX acima, abra o app do seu banco preferido, vá na área PIX e cole o código PIX para efetuar o pagamento!</p> */}
                        <p><strong>O Código expira em 5 minutos!</strong></p>
                      </PixArea>
                    )}
                    {userPaymentMethod.name.toString() === 'withdrawal' && (
                      <p>
                        O seu pedido irá para preparo. Acompanhe o status na
                        opção
                        <strong> Meus Pedidos</strong>. Você receberá um sms
                        assim que seu pedido ficar pronto para retirada. Efetue
                        o pagamento ao retirar o pedido.
                      </p>
                    )}

                    {userPaymentMethod.type.toString() === 'delivery' && (
                      <p>
                        O seu pedido foi enviado ao restaurante. Você receberá
                        um sms assim que seu pedido sair para entrega. Acompanhe
                        o status na opção
                        <strong> Meus Pedidos</strong>. Efetue o pagamento ao
                        receber o pedido.
                      </p>
                    )}
                  </>
                );
              }
              return (
                <>
                  {userPaymentMethod.name.toString() === 'picpay' &&
                    (userDeliveryType.toString() === 'withdrawal' ||
                      !userDeliveryType) && (
                      <p>
                        Assim que o pagamento for realizado e confirmado, o seu
                        pedido irá para preparo. Caso ainda não o tenha
                        efetuado, basta clicar no botão PicPay abaixo. Acompanhe
                        o status na opção
{' '}
<strong> Meus Pedidos</strong>
                        {' '}
                        e fique
                        atento a senha do seu pedido no painel eletrônico da
                        loja.
</p>
                    )}
                  {userPaymentMethod.name.toString() === 'pix_online' &&
                    (userDeliveryType.toString() === 'withdrawal' ||
                      !userDeliveryType) && (
                        <PixArea>
                          <p>Código Pix Copia e Cola</p>
                          <input value={pixCodePayment}></input>
                          <ConfirmPixButton onClick={() =>  {navigator.clipboard.writeText(pixCodePayment); setButtonCopyText('Código Copiado!')}}>
                            {buttonCopyText}
                          </ConfirmPixButton>
                          <HelpPixButton style={{marginTop: 5}} onClick={() => {setIsOpen(true)}}>Como pagar com Pix</HelpPixButton>
                          {/* <p>Copie o código PIX acima, abra o app do seu banco preferido, vá na área PIX e cole o código PIX para efetuar o pagamento!</p> */}
                          <p><strong>O Código expira em 5 minutos!</strong></p>
                        </PixArea>
                  )}

                  {userPaymentMethod.name.toString() === 'picpay' &&
                    userDeliveryType.toString() === 'delivery' && (
                      <p>
                        Assim que o pagamento for realizado e confirmado, o seu
                        pedido irá para preparo. Caso ainda não o tenha
                        efetuado, basta clicar no botão PicPay abaixo. Acompanhe
                        o status na opção
{' '}
<strong> Meus Pedidos</strong>{' '}
                      </p>
                    )}
                  {userPaymentMethod.name.toString() === 'pix_online' &&
                    userDeliveryType.toString() === 'delivery' && (
                      <PixArea>
                        <p>Código Pix Copia e Cola</p>
                        <input value={pixCodePayment}></input>
                        <ConfirmPixButton onClick={() =>  {navigator.clipboard.writeText(pixCodePayment); setButtonCopyText('Código Copiado!')}}>
                          {buttonCopyText}
                        </ConfirmPixButton>
                        <HelpPixButton style={{marginTop: 5}} onClick={() => {setIsOpen(true)}}>Como pagar com Pix</HelpPixButton>
                        {/* <p>Copie o código PIX acima, abra o app do seu banco preferido, vá na área PIX e cole o código PIX para efetuar o pagamento!</p> */}
                        <p><strong>O Código expira em 5 minutos!</strong></p>
                      </PixArea>
                    )}

                  {userPaymentMethod.type.toString() === 'online' &&
                    userPaymentMethod.name.toString() === 'credito' &&
                    (userDeliveryType.toString() === 'withdrawal' ||
                      !userDeliveryType) && (
                      <p>
                        Assim que o pagamento for realizado e confirmado, o seu
                        pedido irá para preparo. Acompanhe o status na opção
                        <strong> Meus Pedidos</strong> e fique atento a senha do
                        seu pedido no painel eletrônico da loja.
                      </p>
                    )}

                  {userPaymentMethod.type.toString() === 'online' &&
                    userPaymentMethod.name.toString() === 'credito' &&
                    userDeliveryType.toString() === 'delivery' && (
                      <p>
                        Assim que o pagamento for realizado e confirmado, o seu
                        pedido irá para preparo. Acompanhe o status na opção
                        <strong> Meus Pedidos</strong>.
                      </p>
                    )}

                  {userPaymentMethod.name.toString() === 'withdrawal' && (
                    <p>
                      O seu pedido foi enviado ao restaurante. Acompanhe o
                      status na opção
                      <strong> Meus Pedidos</strong> e fique atento a senha do
                      seu pedido no painel eletrônico da loja. Efetue o
                      pagamento ao retirar o pedido.
                    </p>
                  )}

                  {userPaymentMethod.type.toString() === 'delivery' && (
                    <p>
                      O seu pedido foi enviado ao restaurante. Acompanhe o
                      status na opção
                      <strong> Meus Pedidos</strong>. Efetue o pagamento ao
                      receber o pedido.
                    </p>
                  )}
                </>
              );
            }
            return (
              <>
                {userPaymentMethod.name.toString() === 'picpay' && (
                  <p>
                    Assim que o pagamento for realizado e confirmado, o seu
                    pedido irá para preparo. Caso ainda não o tenha efetuado,
                    basta clicar no botão PicPay abaixo. Acompanhe o status na
                    opção
{' '}
<strong>Meus Pedidos</strong> e fique atento a senha
                    do seu pedido no painel eletrônico da loja.
                  </p>
                )}

                {userPaymentMethod.name.toString() === 'pix_online' && (
                  <PixArea>
                    <p>Código Pix Copia e Cola</p>
                    <input value={pixCodePayment}></input>
                    <ConfirmPixButton onClick={() =>  {navigator.clipboard.writeText(pixCodePayment); setButtonCopyText('Código Copiado!')}}>
                          {buttonCopyText}
                    </ConfirmPixButton>
                    <HelpPixButton style={{marginTop: 5}} onClick={() => {setIsOpen(true)}}>Como pagar com Pix</HelpPixButton>
                    {/* <p>Copie o código PIX acima, abra o app do seu banco preferido, vá na área PIX e cole o código PIX para efetuar o pagamento!</p> */}
                    <p><strong>O Código expira em 5 minutos!</strong></p>
                  </PixArea>
                )}

                {userPaymentMethod.type.toString() === 'online' &&
                  userPaymentMethod.name.toString() === 'credito' && (
                    <p>
                      Assim que o pagamento for realizado e confirmado, o seu
                      pedido irá para preparo. Acompanhe o status na opção
{' '}
                      <strong>Meus Pedidos</strong>
{' '}
e fique atento a senha do
                      seu pedido no painel eletrônico da loja.
</p>
                  )}

                {userPaymentMethod.type.toString() === 'online' &&
                  userPaymentMethod.name.toString() === 'credito' &&
                  userDeliveryType.toString() === 'delivery' && (
                    <p>
                      Assim que o pagamento for realizado e confirmado, o seu
                      pedido irá para preparo. Acompanhe o status na opção
                      <strong> Meus Pedidos</strong>.
                    </p>
                  )}

                {userPaymentMethod.name.toString() === 'withdrawal' && (
                  <p>
                    O seu pedido foi enviado ao restaurante. Acompanhe o status
                    na opção <strong>Meus Pedidos</strong>
{' '}
e fique atento a
                    senha do seu pedido no painel eletrônico da loja. Efetue o
                    pagamento ao retirar o pedido.
</p>
                )}

                {userPaymentMethod.type.toString() === 'delivery' && (
                  <p>
                    O seu pedido irá para preparo. Acompanhe o status na opção
                    <strong> Meus Pedidos</strong>
. Efetue o pagamento ao
                    receber o pedido.
</p>
                )}
              </>
            );
          })()}

          {userPaymentMethod.name.toString() === 'picpay' && (
            <PaymentLink href={`picpay://picpay/checkout/${picpayReferenceId}`} target="_blank">
              <img src={logoPicpay} alt="logo PicPay" />
              <p>Ir para o Picpay!</p>
            </PaymentLink>
          )}
        </Message>

        {userPaymentMethod.name.toString() === 'withdrawal' ||
        userPaymentMethod.type.toString() === 'delivery' ? (
          <Button onClick={handleNavigate}>Ok, entendi !</Button>
        ) : (
          <Button onClick={handleNavigate}>{buttonText}</Button>
        )}
      </Content>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Como pagar pedidos com Pix</p>
        </ModalTitle>
        <ModalBody>
          <div>
            <img src={logopix} alt="logopix" />
          </div>
          <p><strong>1º Passo</strong></p>
          <p>Copie o código que foi gerado</p>
          <p style={{marginTop: 13}}><strong>2º Passo</strong></p>
          <p>Abra um aplicativo em que você tenha o Pix habilitado e use a opção Pix Copia e Cola</p>
          <p style={{marginTop: 13}}><strong>3º Passo</strong></p>
          <p>Cole o código, confirme o valor e faça o pagamento. Ele será confirmado em no máximo 2 minutos!</p>
        </ModalBody>
        <ModalFooter>
          <ConfirmButton onClick={closeModal}>
            Ok, entendi!
          </ConfirmButton>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default AlmostThere;
