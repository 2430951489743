import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConfirmPixButton = styled.button`
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #32bcad;
  border: none;
  border-radius: 7px;
  margin-top: 10px;
  margin-bottom: 14px;
  margin: auto;
`;

export const HelpPixButton = styled.button`
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: center;
  padding: 7px;
  font-size: 14px;
  color: red;
  background: transparent;
  border: none;
  border-radius: 7px;
  margin-top: 10px;
  margin-bottom: 14px;
  margin: auto;
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalBody = styled.div`
  width: 100%;
  max-width: 410px;
  margin-top: 8px;
  text-align: center;

  input {
    margin-top: 18px;
  }

  img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }

  p {
    margin-top: 5px;
  }
`;


export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #4ceb5b;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: space-around;
`;

export const PixArea = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;

  p {
    text-align: center;
    font-size: 15px;
  }
  input {
    width: 95%;
    margin: auto;
    height: 48px;
    color: black;
    background: transparent;
    border-radius: 7px;
    border: 1px solid gray;
    padding: 3px;
    margin-bottom: 10px;
    text-align: center;

    &::placeholder {
      text-align: center;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Message = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;

  img {
    width: 210px;
  }

  h1 {
    margin-top: 24px;
  }

  p {
    margin-top: 10px;
    text-align: center;

    span {
      margin-top: 20px;
    }
  }
`;

export const PaymentLink = styled.a`
  width: 140px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #4ceb5b;
  font-weight: bold;
  color: #fff;
  margin-top: 32px;
  margin-bottom: 32px;
  cursor: pointer;
  border-radius: 7px;
  text-decoration: none;
  img {
    width: 90px;
  }
`;

export const Button = styled.button`
  width: 100%;
  max-width: 414px;
  height: 48px;
  position: fixed;
  bottom: 0px;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  color: #fff;
  border-radius: 7px 7px 0 0;
  border: none;
`;
