import React, { useEffect, useState, useCallback } from 'react';

import api from '../../../services/api';
import { uuid } from 'uuidv4';

import Pending from './Pending';
import Done from './Done';
import Accepted from './Accepted';
import PaymentPending from './PaymentPending';

import LoadingOrders from '../../../pages/LoadingOrders';

import pedidoVazio from '../../../assets/images/pedidoVazio.svg';

import { Container, Content, Orders, EmptyOrders } from './styles';

const OrdersInProgress = () => {
  const [ordersInProgress, setOrdersInProgress] = useState(['']);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    const response = await api.get('client/orders');

    const orders = response.data;

    const checkDone = orders.filter(order => order.order_status === 'done');
    const checkWaitingPayment = orders.filter(
      order => order.order_status === 'waiting_payment',
    );
    const checkAccepeted = orders.filter(
      order => order.order_status === 'accepted',
    );
    const checkPending = orders.filter(
      order => order.order_status === 'pending',
    );

    setOrdersInProgress([
      ...checkDone,
      ...checkWaitingPayment,
      ...checkAccepeted,
      ...checkPending,
    ]);

    setIsLoading(false);
  }, []);

  useEffect(() => {
    try {
      setIsLoading(true);
      fetchData();
    } catch (err) {
      console.log(err);
    }
  }, [fetchData]);

  return isLoading ? (
    <LoadingOrders />
  ) : (
    <Container>
      <Content>
        <Orders>
          {ordersInProgress
            .filter(order => order.order_status === 'done')
            .map(order => (
              <Done order={order} key={uuid()} />
            ))}
          {ordersInProgress
            .filter(order => order.order_status === 'waiting_payment')
            .map(order => (
              <PaymentPending
                order={order}
                key={uuid()}
                fetchData={fetchData}
              />
            ))}
          {ordersInProgress
            .filter(order => order.order_status === 'accepted')
            .map(order => (
              <Accepted order={order} key={uuid()} />
            ))}
          {ordersInProgress
            .filter(order => order.order_status === 'pending')
            .map(order => (
              <Pending order={order} key={uuid()} />
            ))}
        </Orders>
        {ordersInProgress.length < 1 ? (
          <EmptyOrders>
            <h1>
              Ops!
              <br />
              <span>...não econtramos pedidos por aqui.</span>
            </h1>
            <img
              src={pedidoVazio}
              alt="ilustracao de um homem com uma lupa procurando um pedido"
            />
          </EmptyOrders>
        ) : (
          ''
        )}
      </Content>
    </Container>
  );
};

export default OrdersInProgress;
