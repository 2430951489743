import React from 'react';

import { Container, Content, Footer } from './styles';

import { useOrder } from '../../context/order';

import errorImage from '../../assets/images/ERROR.png';

const OrderError = ({ error, errorMessage, errorAction }) => {
  const { setErrorComponent } = useOrder();

  function handleNavigate() {
    errorAction[0]();

    setErrorComponent(false);
  }

  return (
    <Container error={error}>
      <Content>
        <img src={errorImage} alt="ilustracao de erro ao fazer pedido" />

        <h1>Erro ao fazer pedido!</h1>
        <p>{errorMessage}</p>
      </Content>
      <Footer>
        <button type="button" onClick={handleNavigate}>
          Ok, entendi
        </button>
      </Footer>
    </Container>
  );
};

export default OrderError;
