import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  margin: 0 auto;
  background: #f8f8f8;
  min-height: 100vh;
  width: 100%;
  max-width: 410px;
`;

export const Content = styled.div`
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 80px;
`;

export const Header = styled.header`
  position: fixed;
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;

  -webkit-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);

  h1 {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const TotalPrice = styled.p`
  font-size: 20px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
`;

export const Footer = styled.footer`
  width: 100%;
  max-width: 410px;
  height: 65px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-top: 1px solid #f8f8f8;
  position: fixed;
  bottom: 0;
  padding: 10px;

  z-index: 90;

  -webkit-box-shadow: 31px 0px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow:31px 0px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 31px 0px 31px -20px rgba(120, 120, 120, 1);

  div {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button {
    width: 60%;
    max-width: 414px;
    height: 48px;
    background: linear-gradient(
      144deg,
      rgba(251, 74, 32, 1) 28%,
      rgba(250, 5, 97, 1) 100%
    );
    color: #fff;
    border-radius: 7px;
    border: none;
    font-size: 16px;
  }
`;

export const Orders = styled.div`
  width: 100%;
  max-width: 410px;
  min-height: 160px;
  /* max-height: 200px; */
  background: #fff;
  padding: 10px;

  margin-top: 70px;
  display: flex;
  flex-direction: column;

  overflow-x: auto;
  -webkit-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);

  h3 {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
  }
`;

export const Order = styled.div`
  display: flex;
  margin-top: 10px;
  padding-top: 10px;
  align-items: flex-start;

  & + div {
    border-top: 1px solid #33333320;
    padding-top: 16px;
  }
`;

export const OrderImage = styled.div`
  img {
    width: 56px;
    border-radius: 7px;
  }
`;

export const OrdersDetails = styled.div`
  margin-left: 8px;
`;

export const OrderPrice = styled.p`
  margin-top: 4px;
  font-weight: bold;
`;

export const OrderProduct = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const OrderComplement = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;

  p {
    color: #fb4a20;
    font-size: 10px;
    padding: 4px;
    border: 1px solid #fb4a20;
    border-radius: 14px;
    margin-right: 4px;
  }
`;

export const OrderObservations = styled.p`
  font-size: 12px;
  margin-top: 4px;
`;

export const PaymentMethodArea = styled.div`
  width: 100%;
  max-width: 410px;
  margin-top: 10px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);

  padding: 10px;

  h3 {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const PaymentMethod = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 18px;
  }

  button {
    border: none;
    background: none;
    color: #fb4a20;
    border-radius: 7px;
    padding: 10px 15px;
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const PaymentMethodType = styled.div`
  margin-top: 8px;

  p {
    font-size: 14px;

    & + p {
      margin-top: 4px;
    }
  }
`;

export const AddressArea = styled.div`
  width: 100%;
  max-width: 410px;
  margin-top: 10px;
  background: #fff;

  padding: 10px;

  -webkit-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);

  h3 {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const ConfirmAddress = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  p {
    font-size: 14px;

    & + p {
      margin-top: 4px;
    }
  }

  button {
    border: none;
    background: none;
    color: #fb4a20;
    border-radius: 7px;
    padding: 10px 15px;
    font-weight: bold;
    text-decoration: underline;
  }
`;

export const CouponArea = styled.div`
  width: 100%;
  max-width: 410px;
  margin-top: 10px;
  background: #fff;

  padding: 10px;

  -webkit-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);

  h3 {
    font-size: 14px;
    font-weight: bold;
  }

  > p {
    padding: 10px;
  }
`;

export const InputCoupon = styled.div`
  padding: 10px;

  input {
    border: 1px solid #33333320;
    border-radius: 7px;
    padding: 10px 15px;

    &::placeholder {
      font-size: 14px;
    }
  }

  button {
    border: none;
    border-radius: 7px;
    padding: 10px 15px;
    margin-left: 4px;
    background: linear-gradient(
      144deg,
      rgba(251, 74, 32, 1) 28%,
      rgba(250, 5, 97, 1) 100%
    );
    color: #fff;
  }
`;

export const CouponStatus = styled.p`
  padding: 10px;
  font-weight: bold;

  ${props =>
    props.status.toString() === 'activated' &&
    css`
      color: green;
    `}

  ${props =>
    props.status.toString() === 'activated' &&
    css`
      color: green;
    `}


  ${props =>
    props.status.toString() === 'error' &&
    css`
      color: #fb4a20;
    `}
`;

export const CouponSelect = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
`;

export const CouponDiscount = styled.p`
  font-size: 14px;
  color: #fb4a20;
  text-decoration: line-through;
`;

export const Coupon = styled.div`
  padding: 10px 15px;
  border: 1px solid #fb4a20;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  color: #fb4a20;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
`;

export const CouponInfo = styled.p`
  font-size: 10px;
  font-weight: normal;
`;

export const RouterDomLink = styled(Link)`
  text-decoration: none;
  color: #fb4a20;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;
