import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { uuid } from 'uuidv4';

import { FiArrowLeft } from 'react-icons/fi';
import { FaHandHoldingUsd } from 'react-icons/fa';

import { useAuth } from '../../context/auth';

import {
  Container,
  Content,
  Header,
  PaymentsMethods,
  PaymentsMethodTitle,
  ModalTitle,
  ModalFooter,
  ConfirmButton,
  Payment,
  PicPayInvite,
  PaymentsMethodHeader,
  UserChangeInput,
  CancelButton,
  DeliveryTax
} from './styles';

import logopicpay from '../../assets/images/logopicpay.png';
import logopix from '../../assets/images/logopix.png';
import logocartao from '../../assets/images/logocartao.png';
import logodinheiro from '../../assets/images/dinheiro.svg';
import visa from '../../assets/images/visa.png';
import pix from '../../assets/images/pix.png';

import masterCard from '../../assets/images/mastercard.png';
import american from '../../assets/images/american.png';
import diners from '../../assets/images/diners.png';
import elo from '../../assets/images/elo.png';
import hipercard from '../../assets/images/hipercard.png';

const Payments = () => {
  const {
    setUserPaymentMethod,
    restaurantAcceptsPaytime,
    restaurantAcceptsPicpay,
    restaurantAcceptsPix,
    restaurantAcceptsWithdrawal,
    restaurantAcceptsDelivery,
    restaurantDeliveryActive,
    userDeliveryType,
    setUserChange,
    restaurantDeliveryPayments,
    restaurantDeliveryTax,
    isDeliveryByDistance
  } = useAuth();
  const history = useHistory();

  // modal style
  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  Modal.setAppElement('#root');
  const [userChangeSet, setUserChangeSet] = useState();
  const [deliveryPayments, ] = useState(() => {
    const paymentsFiltered = restaurantDeliveryPayments.filter(
      payment => payment.delivery_active,
    );

    return paymentsFiltered;
  }, []);

  function getPaymentImage(payment_method){
    const payment = {
      dinheiro: logodinheiro,
      visa_credito: visa,
      visa_debito: visa,
      mastercard_credito: masterCard,
      mastercard_debito: masterCard,
      elo_credito: elo,
      elo_debito: elo,
      hipercard_credito: hipercard,
      hipercard_debito: hipercard,
      diners_club_credito: diners,
      diners_club_debito:diners,
      american_express_credito: american,
      american_express_debito: american,
      pix: pix,
      picpay_offline: logopicpay,
    }

    return payment[payment_method]
  }

  function closeModalWithChange() {
    setIsOpen(false);
    setUserChange(userChangeSet);
    history.push('/app/confirmorder');
  }

  function closeModal() {
    setIsOpen(false);
    history.push('/app/confirmorder');
    setUserChange(0);
  }

  function openModal() {
    setIsOpen(true);
  }

  function handleNavigate(e, type, card_flag = '') {
    if (userDeliveryType.toString() === 'delivery') {
      if (e.id === 'Dinheiro') {
        setUserPaymentMethod({
          type,
          name: 'Dinheiro',
          keyword: card_flag,
        });

        openModal();
      } else {
        setUserPaymentMethod({
          type,
          name: e.id,
          keyword: card_flag,
        });
        history.push('/app/confirmorder');
      }
    } else {
      setUserPaymentMethod({
        type,
        name: e.id,
        keyword: card_flag,
      });
      history.push('/app/confirmorder');
    }
  }

  function handleBack() {
    if (
      restaurantAcceptsDelivery.toString() === 'true' &&
      restaurantDeliveryActive.toString() === 'true' &&
      userDeliveryType.toString() === 'delivery' &&
      isDeliveryByDistance.toString() === 'true'
    ) {
      history.push('/app/searchaddressbydistance');
    } else if (
      restaurantAcceptsDelivery.toString() === 'true' &&
      restaurantDeliveryActive.toString() === 'true' &&
      userDeliveryType.toString() === 'delivery'
    ) {
      history.push('/app/searchaddress');
    } else if (
      restaurantAcceptsDelivery.toString() === 'true' &&
      userDeliveryType.toString() === 'withdrawal'
    ) {
      history.push('/app/deliverytype');
    } else {
      history.push('/app/cart');
    }
  }



  return (
    <Container>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>Troco pra quanto?</p>
        </ModalTitle>
        <UserChangeInput
          value={userChangeSet}
          onChange={e => {
            setUserChangeSet(e.target.value);
          }}
        />
        <ModalFooter>
          <CancelButton onClick={closeModal}>Não quero troco</CancelButton>
          <ConfirmButton onClick={closeModalWithChange}>
            Confirmar
          </ConfirmButton>
        </ModalFooter>
      </Modal>
      <Content>
        <Header>
          <FiArrowLeft color="#fb4a20" onClick={handleBack} />
          <h1>Pagamento</h1>
        </Header>
        <PaymentsMethods>
          <PaymentsMethodTitle>Como você deseja pagar?</PaymentsMethodTitle>
          {(restaurantAcceptsPicpay.toString() === 'true' ||
          restaurantAcceptsPix.toString() === 'true' ||
          restaurantAcceptsPaytime.toString() === 'true') && (
              <PaymentsMethodHeader>
                <p>Pagar online</p>
              </PaymentsMethodHeader>
          )}

          {restaurantAcceptsPix.toString() === 'true' && (
            <Payment
              onClick={e => {
                handleNavigate(e.currentTarget, 'online');
              }}
              id="pix_online"
            >
              <div>
                <img src={logopix} alt="logopix" />
              </div>
              <p>Pix Online</p>
            </Payment>
          )}

          {restaurantAcceptsPicpay.toString() === 'true' && (
            <Payment
              onClick={e => {
                handleNavigate(e.currentTarget, 'online');
              }}
              id="picpay"
            >
              <div>
                <img src={logopicpay} alt="logopicpay" />
              </div>
              <p>PicPay</p>
            </Payment>
          )}

          {/* {restaurantAcceptsPaytime.toString() === 'true' && (
            <Payment
              onClick={e => {
                handleNavigate(e.currentTarget, 'online');
              }}
              id="credito"
            >
              <div>
                <img
                  src={logocartao}
                  alt="logocartao"
                  style={{ width: '50px' }}
                />
              </div>
              <p>Crédito</p>
            </Payment>
          )} */}

          {restaurantAcceptsDelivery.toString() === 'true' &&
          restaurantDeliveryActive.toString() === 'true' ? (
            <>
              {restaurantAcceptsWithdrawal.toString() === 'true' &&
                userDeliveryType.toString() === 'withdrawal' && (
                  <>
                    <PaymentsMethodHeader>
                      <p>Pagar na retirada</p>
                    </PaymentsMethodHeader>

                    <Payment
                      onClick={e => {
                        handleNavigate(e.currentTarget, 'withdrawal');
                      }}
                      id="withdrawal"
                    >
                      <div>
                        <img src={logodinheiro} alt="logodinheiro" />
                      </div>

                      <p>Pagar na retirada</p>
                    </Payment>
                  </>
                )}

              {userDeliveryType.toString() === 'delivery' && (
                <>
                  <PaymentsMethodHeader>
                    <p>Pagar na entrega</p>
                    {restaurantDeliveryTax && restaurantDeliveryTax > 0 ? (
                      <DeliveryTax>Taxa de entrega: R$ {restaurantDeliveryTax}</DeliveryTax> ) : (
                      <DeliveryTax>Entrega grátis</DeliveryTax>)
                    }
                  </PaymentsMethodHeader>

                  {!!deliveryPayments &&
                    deliveryPayments.map(method => (
                      <Payment
                        key={uuid()}
                        onClick={e => {
                          handleNavigate(
                            e.currentTarget,
                            'delivery',
                            method.keyword,
                          );
                        }}
                        id={method.name}
                      >
                        <div>
                          <img
                            src={
                              getPaymentImage(method.keyword)
                            }
                            alt={method.name}
                          />
                        </div>

                        <p>{method.name}</p>
                      </Payment>
                    ))}
                </>
              )}


              {restaurantAcceptsPicpay.toString() === 'true' && (
                <PicPayInvite>
                  <h1>Você ainda não baixou o PicPay?</h1>
                  <p>
                    Que tal baixar agora e no seu primeiro pagamento usando
                    cartão de crédito ganhar de volta R$10 ?
                  </p>

                  <a
                    href="http://www.picpay.com/convite?@TAKEAT"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaHandHoldingUsd size={20} />
                    Quero o cupom!
                  </a>
                </PicPayInvite>
              )}
            </>
          ) : (
            <>
              {restaurantAcceptsWithdrawal.toString() === 'true' && (
                // userDeliveryType.toString() === 'withdrawal' &&

                <>
                  <PaymentsMethodHeader>
                    <p>Pagar na retirada</p>
                  </PaymentsMethodHeader>

                  <Payment
                    onClick={e => {
                      handleNavigate(e.currentTarget, 'withdrawal');
                    }}
                    id="withdrawal"
                  >
                    <div>
                      <img src={logodinheiro} alt="logodinheiro" />
                    </div>

                    <p>Pagar na retirada</p>
                  </Payment>
                </>
              )}

              {/* <PaymentsMethodHeader>
                <p>Pagar online</p>
              </PaymentsMethodHeader>

              {restaurantAcceptsPicpay.toString() === 'true' && (
                <Payment
                  onClick={e => {
                    handleNavigate(e.currentTarget, 'online');
                  }}
                  id="picpay"
                >
                  <div>
                    <img src={logopicpay} alt="logopicpay" />
                  </div>

                  <p>PicPay</p>
                </Payment>
              )}

              {restaurantAcceptsPaytime.toString() === 'true' && (
                <Payment
                  onClick={e => {
                    handleNavigate(e.currentTarget, 'online');
                  }}
                  id="credito"
                >
                  <div>
                    <img
                      src={logocartao}
                      alt="logocartao"
                      style={{ width: '50px' }}
                    />
                  </div>

                  <p>Crédito</p>
                </Payment>
              )} */}

              {restaurantAcceptsPicpay.toString() === 'true' && (
                <PicPayInvite>
                  <h1>Você ainda não baixou o PicPay?</h1>
                  <p>
                    Que tal baixar agora e no seu primeiro pagamento usando
                    cartão de crédito ganhar de volta R$10 ?
                  </p>

                  <a
                    href="http://www.picpay.com/convite?@TAKEAT"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaHandHoldingUsd size={20} />
                    Quero o cupom!
                  </a>
                </PicPayInvite>
              )}
            </>
          )}
        </PaymentsMethods>
      </Content>
    </Container>
  );
};

export default Payments;
