import React from 'react';
import iconTake from '../../assets/images/iconTake.svg';

import { Container } from './styles';

const LoadingPayment = ({ title }) => {
  return (
    <Container>
      {' '}
      <img src={iconTake} alt="logo Takeat" /> <p>{title}</p>
    </Container>
  );
};

export default LoadingPayment;
