import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Rating from 'react-rating';

import api from '../../services/api';

import { useAuth } from '../../context/auth';

import avaliar from '../../assets/images/avaliar.svg';

import { FaStar } from 'react-icons/fa';

import { Container, Content, Message, Observations, Coment } from './styles';

const RestaurantRating = () => {
  const { orderBasketId } = useAuth();
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState([]);
  const [clientRating, setClientRating] = useState(0);
  const [coment, setComent] = useState('');

  async function handleSubmit() {
    try {
      await api.post('/client/ratings', {
        stars: clientRating,
        ratings: selectedItem,
        comment: coment,
        order_basket_id: orderBasketId,
      });

      history.push('/app/orders');
    } catch (error) {
      console.log(error);
    }
  }

  function handleSelectRating(rating) {
    const findItem = selectedItem.findIndex(item => item === rating);

    if (findItem >= 0) {
      const filteredItems = selectedItem.filter(item => item !== rating);

      setSelectedItem(filteredItems);
    } else {
      setSelectedItem([...selectedItem, rating]);
    }
  }

  function handleInputComent(coment) {
    setComent(coment);
  }

  function handleRate(rate) {
    setClientRating(rate);
  }

  const compliments = [
    'Tempero',
    'Atendimento',
    'Inovação',
    'Agilidade',
    'Limpeza',
  ];
  const criticism = ['Sujeira', 'Atendimento ruim', 'Sem tempero'];

  return (
    <Container>
      <Content>
        <Message>
          <img src={avaliar} alt="ilustracao avaliacao" />
          <h1>Avalie nosso Shopping</h1>

          <p>
            <Rating
              emptySymbol={<FaStar size={28} color="#33333330" />}
              fullSymbol={<FaStar size={28} color="#ffbd0c" />}
              onChange={rate => {
                handleRate(rate);
              }}
              onClick={rate => {
                handleRate(rate);
              }}
              initialRating={clientRating}
            />
          </p>
          <Coment
            name="coment"
            maxLength="140"
            placeholder="Deixe-nos seu comentário ..."
            onChange={e => {
              handleInputComent(e.target.value);
            }}
            value={coment}
          />
        </Message>

        {clientRating >= 3 && (
          <>
            <Observations>
              {compliments.map(compliment => (
                <li
                  key={compliment}
                  onClick={() => {
                    handleSelectRating(compliment);
                  }}
                  className={
                    selectedItem.includes(compliment) ? 'selected' : ''
                  }
                >
                  {compliment}
                </li>
              ))}
            </Observations>

            <button onClick={handleSubmit} className="btn-primary">
              {' '}
              Enviar{' '}
            </button>
          </>
        )}

        {(clientRating >= 1) & (clientRating < 3) ? (
          <>
            <Observations>
              {criticism.map(critic => (
                <li
                  key={critic}
                  onClick={() => {
                    handleSelectRating(critic);
                  }}
                  className={selectedItem.includes(critic) ? 'selected' : ''}
                >
                  {critic}
                </li>
              ))}
            </Observations>

            <button onClick={handleSubmit} className="btn-primary">
              {' '}
              Enviar{' '}
            </button>
          </>
        ) : (
          ''
        )}
      </Content>
    </Container>
  );
};

export default RestaurantRating;
