import styled from 'styled-components';

import Select from 'react-select';

export const Container = styled.div`
  margin: 0 auto;
  background: #f8f8f8;
  width: 100%;
  max-width: 410px;

  /* display: flex;
  justify-content: center;
  align-items: center; */
`;

export const Content = styled.div`
  color: #333;
  background-color: #fff;
  padding-bottom: 64px;

  min-height: 100vh;

  display: flex;
  flex-direction: column;

  align-items: center;
`;

export const Header = styled.header`
  position: fixed;
  width: 100%;
  max-width: 410px;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 10px;
  padding-right: 10px;

  -webkit-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);

  h1 {
    font-size: 18px;
  }
`;

export const Text = styled.div`
  margin-top: 80px;
  width: 90%;

  p {
    text-align: center;
    font-size: 14px;
  }
`;

export const SelectGroup = styled.div`
  margin-top: 24px;
  width: 100%;
  max-width: 410px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SelectField = styled.div`
  width: 90%;
  max-width: 410px;
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    text-align: left;
    margin-bottom: 4px;
  }

  & + div {
    margin-top: 24px;
  }
`;

export const SelectStyled = styled(Select)`
  width: 100%;

  & + & {
    margin-top: 16px;
  }
`;

export const AddressField = styled.div`
  width: 90%;
  max-width: 410px;
  margin-top: 24px;

  border-top: 1px solid #33333330;
  padding-top: 24px;

  p {
    text-align: left;
    font-weight: 700;
  }
`;

export const StreetInputField = styled.div`
  display: flex;
`;

export const InputField = styled.div`
  p {
    font-size: 14px;
    font-weight: normal;
  }

  margin-top: 16px;
  margin-right: 4px;

  input {
    border-radius: 5px;
    border: 1px solid #33333330;
    padding: 5px 10px;
    width: 100%;
  }
`;

export const AcceptsPhrase = styled.div`
  max-width: 90%;

  margin-top: 16px;
`;

export const ComplementAddress = styled.div``;

export const ButtonOrders = styled.button`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 414px;
  height: 48px;
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 7px 7px 0px 0px;

  svg {
    margin-right: 4px;
  }
`;

export const Main = styled.div`
  margin-top: 110px;
  width: 100%;

  padding-bottom: 55px;
`;

export const Nav = styled.nav`
  width: 100%;
  height: 40px;
  max-width: 410px;
  background: #fff;
  position: fixed;
  top: 60px;

  border-bottom: 1px solid #33333310;

  -webkit-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);

  display: flex;
  justify-content: center;
  padding-left: 24px;

  li {
    display: inline-flex;
    list-style: none;
    margin-right: 24px;
    color: #33333390;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;

    & + li {
      border-left: 1px solid #33333390;
      padding-left: 24px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const UserAddress = styled.div`
  width: 90%;
  min-height: 120px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  color: #333;
  border-radius: 7px;
  padding: 8px;
  text-decoration: none;
  -webkit-box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);

  & + div {
    margin-top: 16px;
  }
`;

export const ButtonGroup = styled.div`
  width: 90%;
  display: flex;
  justify-content: flex-end;
`;

export const ChangeAddress = styled.button`
  background: transparent;
  border: none;
  color: rgba(251, 74, 32, 1);
  border: 1px solid rgba(251, 74, 32, 1);
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 4px;
`;

export const VerifyAddress = styled.button`
  background: linear-gradient(
    144deg,
    rgba(251, 74, 32, 1) 28%,
    rgba(250, 5, 97, 1) 100%
  );
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
`;

export const ValidAddressTitle = styled.div`
  margin-top: 100px;

  p {
    font-weight: bold;
  }
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalBody = styled.div`
  width: 100%;
  max-width: 410px;
  margin-top: 8px;

  input {
    margin-top: 18px;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;

  border-top: 1px solid #33333310;

  display: flex;
  justify-content: space-around;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #77dd77;
  border: none;
  border-radius: 7px;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #ff1814;
  border: none;
  border-radius: 7px;
  margin-left: 4px;
`;
