import React, {createContext, useContext, useMemo, useEffect, useState} from 'react';
import api from '../../services/api'
import {useAuth} from '../auth'

const MenuContext = createContext()

const MenuProvider = ({children}) => {
  const { restaurantId } = useAuth();

  const [menu, setMenu] = useState(()=>{
    const menuStorage = localStorage.getItem('@qrexpress:menu');

    if(menuStorage){
      return JSON.parse(menuStorage)
    }

    return []
  });


  async function getMenu(){

    const response = await api.get(
      `public/restaurants/menu/${restaurantId}`,
    );
    setMenu(response.data);

  }

  useEffect(()=>{
    try{
      getMenu()
    }catch(e){
      console.log("erro ao carregar menu")
    }
  }, [restaurantId])

  useEffect(() => {
    localStorage.setItem('@qrexpress:menu', JSON.stringify(menu));
  }, [menu]);


  const value = useMemo(()=>({
    menu

  }), [menu])

  return(
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  )

}

function useMenu(){
  const context = useContext(MenuContext);

  if(!context){
    console.log('useMenu must be within a provider');
  }

  return context;
}

export {MenuProvider, useMenu}
