import styled, { css } from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #f8f8f8;
  width: 100%;
  max-width: 410px;
`;

export const Content = styled.div`
  color: #333;
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  align-items: center;
`;

export const Header = styled.header`
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;

  -webkit-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);

  h1 {
    font-size: 18px;
  }
`;

export const OrderDetails = styled.div`
  width: 90%;
  min-height: 100px;

  display: flex;
  flex-direction: column;

  border-radius: 7px;
  background: #fff;
  margin-top: 16px;
  padding: 10px;

  -webkit-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 31px -20px rgba(120, 120, 120, 1);
`;

export const OrderDetailsHeader = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #33333310;
`;

export const OrderDetailsBody = styled.div`
  padding-top: 8px;
`;

export const Orders = styled.div`
  padding-top: 8px;
`;

export const OrderDetailsFooter = styled.div`
  margin-top: 8px;
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
`;

export const OrderTime = styled.p`
  font-size: 12px;
  margin-top: 8px;
`;

export const OrderKey = styled.p`
  margin-top: 8px;
  font-weight: bold;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
`;

export const OrderProduct = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
`;

export const OrderPrice = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;

  p {
    font-weight: bold;
  }
`;

export const OrderDiscount = styled.p`
  text-decoration: line-through;
  color: #ff0000;
`;

export const OrderDiscountArea = styled.div`
  border-top: 1px solid #33333310;
  margin-top: 24px;
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
`;

export const ButtonOrders = styled.button`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 414px;
  height: 48px;
  background: #0aa5ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 7px 7px 0px 0px;

  svg {
    margin-right: 4px;
  }
`;

export const OrderStatus = styled.div`
  ${props =>
    props.status === 'pending' &&
    css`
      p {
        color: #ffa500;
      }
    `}

  ${props =>
    props.status === 'payment_pending' &&
    css`
      p {
        color: #ff0000;
      }
    `}

  ${props =>
    props.status === 'canceled' &&
    css`
      p {
        color: #ff0000;
      }
    `}

  ${props =>
    props.status === 'chargeback' &&
    css`
      p {
        color: #ff0000;
      }
    `}

  ${props =>
    props.status === 'finished' &&
    css`
      p {
        color: #33333380;
      }
    `}

  ${props =>
    props.status === 'done' &&
    css`
      p {
        color: #06c258;
      }
    `}

  ${props =>
    props.status === 'accepted' &&
    css`
      p {
        color: #ffa500;
      }
    `}
`;
