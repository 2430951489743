import React from 'react';

import {
  Order,
  OrderHeader,
  OrderBody,
  OrderFooter,
  OrderStatus,
} from './styles';

const Accepted = ({ order }) => {
  return (
    <Order key={order.id}>
      <OrderHeader>
        <strong>{order.restaurant.fantasy_name}</strong>
        <p>Senha {order.attendance_password.password_number}</p>
      </OrderHeader>
      <OrderBody>
        {order.orders.map(item => (
          <p key={item.id}>{`${item.amount}x ${item.product.name}`}</p>
        ))}
      </OrderBody>
      <OrderStatus status={order.order_status}>
        <p> Preparando o pedido </p>
      </OrderStatus>
      <OrderFooter to={{ pathname: '/app/myordersdetails', state: { order } }}>
        <p>Ver detalhes</p>
      </OrderFooter>
    </Order>
  );
};

export default Accepted;
