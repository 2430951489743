import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 410px;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 55px;

  a {
    text-decoration: none;
    color: #fb4a20;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 700;
  }

  button {
    position: fixed;
    bottom: 0;
  }
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 900;
  }

  p {
    margin-top: 20px;
    text-align: center;
  }

  img {
    width: 200px;
  }
`;

export const Observations = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  list-style: none;
  margin-top: 16px;
  margin-bottom: 48px;
  padding: 10px;

  li {
    background: #fb4a20;
    padding: 10px 15px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    font-size: 12px;
  }

  li.selected {
    background: ${shade(0.2, '#fb4a20')};
  }
`;

export const Coment = styled.textarea`
  margin-top: 16px;
  width: 340px;
  height: 80px;
  border: 1px solid #33333310;
  border-radius: 7px;
  color: #fb4a20;
  padding: 8px;
  text-align: center;

  &::placeholder {
    padding: 8px;
    color: #33333370;
  }

  &:focus {
    border: 1px solid #fb4a2050;
  }
`;
