import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  min-height: 100vh;
  width: 100%;
  max-width: 410px;
`;

export const Content = styled.div`
  color: #333;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Message = styled.div`
  display: flex;

  padding: 15px;
  justify-content: space-between;
  align-items: center;
  color: #333;

  h1 {
    font-size: 16px;
    text-align: center;
  }
`;

export const Restaurants = styled.div`
  width: 100%;
  max-width: 410px;
  height: 100vh;

  display: flex;
  align-items: center;
  padding-bottom: 50px;
`;

export const Restaurant = styled.div`
  width: 100%;
  max-width: 410px;
  background: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ButtonOrders = styled.button`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 414px;
  height: 48px;
  background: #0aa5ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 7px 7px 0px 0px;

  svg {
    margin-right: 4px;
  }
`;

export const PromotionFood = styled.div`
  width: 100px;
  height: 100px;

  div {
    padding-top: 4px;
    padding-bottom: 4px;
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;

    p {
      font-size: 10px;
    }

    img {
      width: 80px;
      height: 75px;
      border-radius: 50%;
      margin-top: 16px;

      border: 5px double #a47a1e;
    }
  }
`;

export const ProfileFood = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 200px;
  }

  p {
    font-weight: bold;
    font-size: 20px;
    margin-top: 8px;
  }
`;

export const InfoFood = styled.div`
  width: 130px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AttributesFood = styled.div`
  height: 75px;
  display: flex;
  align-items: center;
`;

export const TagFood = styled.p`
  height: 20px;
  padding: 6px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background: #fb4a20;
  color: #fff;
  margin-right: 4px;
`;

export const LocalFood = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 300px;
  margin-top: 4px;

  > p {
    font-size: 12px;
    margin-left: 4px;
    text-align: center;
  }
`;

export const PromotionTitle = styled.div`
  position: absolute;
  border-radius: 5px;

  top: -16px;
  width: 70px;
  background: linear-gradient(
    144deg,
    #956d13 15%,
    #ffda7d 30%,
    #e6be69 45%,
    #ffec94 60%,
    #d3a84c 75%,
    #a47a1e 100%
  );

  p {
    font-size: 8px;
    text-transform: uppercase;
    color: #000;
  }
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #4ceb5b;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const RestaurantClosed = styled.div`
  border: 1px solid red;
  border-radius: 7px;
  padding: 10px 15px;
  margin-top: 16px;
  color: #fb4a20;
`
export const RestaurantMinimumPrice = styled.div`
  border: 1px solid #0aa5ff;
  border-radius: 7px;
  padding: 10px 15px;
  margin-top: 16px;
  color: #0aa5ff;

`
