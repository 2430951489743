import axios from 'axios';
// android:http://10.0.2.2:3333
const api = axios.create({
  baseURL: 'https://api.zoop.ws/v1/',
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  auth: {
    username: 'zpk_prod_wJ9FvhZTB7aqXO6wsUf3MvhS',
    password: '',
  },
});

export default api;
