import React, { useState, useRef } from 'react';
import InputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import Switch from 'react-switch';
import { uuid } from 'uuidv4';

import { FiSmartphone, FiArrowLeft, FiXCircle } from 'react-icons/fi';
import formatValue from '../../utils/formatValue';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';

import MenuItemList from './MenuItemList';

import { useCart } from '../../context/cart';
import { useAuth } from '../../context/auth';
import { useOrder } from '../../context/order';

import iconTake from '../../assets/images/iconTake.svg';

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  Footer,
  HeaderAction,
  CartDescription,
  RouterDomLink,
  ItemListHeader,
  MakingOrder,
  Info,
  SwitchUserSms,
  RemoveUser,
} from './styles';

const Cart = () => {
  const {
    setUser,
    setUserChosePaySms,
    userPhone,
    userEmail,
    userName,
    restaurantId,
    userLogin,
    userLogout,
    restaurantHasSmsService,
    clientPaySms,
    isSmsServiceOptional,
    restaurantAcceptsDelivery,
    restaurantDeliveryActive,
  } = useAuth();
  const { cart } = useCart();
  const { setOrdersTotalValue } = useOrder();
  const [makingOrder] = useState(false);
  const [userAcceptedSms, setUserAcceptedSms] = useState(clientPaySms);
  const formRef = useRef(null);

  const history = useHistory();

  const totalProductPrice = cart.reduce((TotalAccumulator, order) => {
    const totalCategories = order.complement_categories.reduce(
      (categoryAccumulator, category) => {
        const totalComplements = category.complements.reduce(
          (complementAccumulator, complement) => {
            return complementAccumulator + complement.price * complement.amount;
          },
          0,
        );
        return categoryAccumulator + totalComplements;
      },
      0,
    );

    return (
      TotalAccumulator +
      (parseFloat(totalCategories) + parseFloat(order.price)) * order.amount
    );
  }, 0);

  function handleToggleSms() {
    if (userAcceptedSms === true) {
      setUserAcceptedSms(false);
    } else {
      setUserAcceptedSms(true);
    }
  }

  function logout() {
    userLogout();
  }

  function handleMakeOrder(firstName, lastName, phone, email) {
    if (!userPhone) {
      if (
        phone.length < 15 ||
        firstName.length <= 0 ||
        email.length <= 0 ||
        lastName.length <= 0
      ) {
        alert('Favor preencher os dados corretamente.');
      } else if (cart.length > 0) {
        setUserChosePaySms(userAcceptedSms);

        setUser(firstName, lastName, phone, email);
        userLogin(phone, firstName, lastName, email);
        setOrdersTotalValue(Number(totalProductPrice));

        if (
          restaurantAcceptsDelivery.toString() === 'true' &&
          restaurantDeliveryActive.toString() === 'true'
        ) {
          history.push('/app/deliverytype');
        } else {
          history.push('/app/payments');
        }
      } else {
        alert(
          'O carrinho está vazio, favor adicionar produtos para efetuar o pedido',
        );

        history.goBack();
      }
    } else if (cart.length > 0) {
      setUserChosePaySms(userAcceptedSms);

      setOrdersTotalValue(Number(totalProductPrice));
      if (
        restaurantAcceptsDelivery.toString() === 'true' &&
        restaurantDeliveryActive.toString() === 'true'
      ) {
        history.push('/app/deliverytype');
      } else {
        history.push('/app/payments');
      }
    } else {
      alert(
        'O carrinho está vazio, favor adicionar produtos para efetuar o pedido',
      );

      history.goBack();
    }
  }

  async function handleSubmit(data) {
    try {
      if (formRef.current) {
        formRef.current.setErrors({});
      }
      const schema = Yup.object().shape({
        firstName: Yup.string()
          .min(2, 'Nome obrigatório')
          .matches(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/, 'Somente letras')
          .required('Nome obrigatório'),
        lastName: Yup.string()
          .min(2, 'Sobrenome obrigatório')
          .matches(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/, 'Somente letras')
          .required('Sobrenome obrigatório'),
        phone: Yup.string().required('Telefone obrigatório'),
        email: Yup.string()
          .email('Email inválido')
          .trim()
          .required('Email obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      const name = data.firstName.trim();
      const lastName = data.lastName.trim();
      const email = data.email.trim();

      handleMakeOrder(name, lastName, data.phone, email);
    } catch (err) {
      const errors = getValidationErrors(err);
      if (formRef.current) {
        formRef.current.setErrors(errors);
      }
    }
  }

  return makingOrder ? (
    <Container>
      <MakingOrder>
        <img src={iconTake} alt="Logo Takeat" />
        <h1>Fazendo seu pedido ...</h1>
      </MakingOrder>
    </Container>
  ) : (
    <Container>
      <Content>
        <HeaderAction>
          <header>
            <RouterDomLink onClick={()=>{window.history.back()}}>
              <FiArrowLeft color="#fb4a20"/>

            </RouterDomLink>
            <h1>Carrinho</h1>
          </header>
        </HeaderAction>
        {userPhone ? (
          <>
            <CartDescription>
              <div>
                <FiSmartphone />
                <p>{userPhone}</p>
              </div>
              <p>{userName}</p>
              <p>{userEmail}</p>
              <RemoveUser onClick={logout}>
                <FiXCircle />
                <p>Limpar meus dados</p>
              </RemoveUser>
              {restaurantHasSmsService === true &&
              isSmsServiceOptional === true ? (
                <SwitchUserSms>
                  <Switch
                    onChange={handleToggleSms}
                    onColor="#fb4a20"
                    checked={userAcceptedSms}
                  />
                  {userAcceptedSms === true ? (
                    <p>Receber SMS quando o pedido estiver pronto</p>
                  ) : (
                    <p>Não desejo receber sms quando o pedido ficar pronto</p>
                  )}
                </SwitchUserSms>
              ) : (
                ''
              )}
            </CartDescription>

            <Footer>
              <div>
                <small>Valor Total</small>
                <p>{formatValue(Number(totalProductPrice))}</p>
              </div>

              <button onClick={handleMakeOrder}>Confirmar</button>
            </Footer>
          </>
        ) : (
          <>
            <CartDescription>
              <strong>Informações</strong>

              <p>
                Insira suas informações abaixo para prosseguir com seu pedido.
              </p>
            </CartDescription>
            <Info>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Input name="firstName" placeholder="Nome" />
                <Input name="lastName" placeholder="Sobrenome" />

                <Input name="email" placeholder="Email" />
                <InputMask mask="+55 (99) 99999-9999" maskChar="">
                  {() => (
                    <Input name="phone" placeholder="Celular" type="text" />
                  )}
                </InputMask>

                <Footer>
                  <div>
                    <small>Valor Total</small>
                    <p>{formatValue(Number(totalProductPrice))}</p>
                  </div>

                  <button type="submit">Confirmar</button>
                </Footer>
              </Form>

              {restaurantHasSmsService === true &&
              isSmsServiceOptional === true ? (
                <SwitchUserSms>
                  <Switch
                    onChange={handleToggleSms}
                    onColor="#fb4a20"
                    checked={userAcceptedSms}
                  />
                  {userAcceptedSms === true ? (
                    <p>Receber SMS quando o pedido estiver pronto</p>
                  ) : (
                    <p>Não desejo receber sms quando o pedido ficar pronto</p>
                  )}
                </SwitchUserSms>
              ) : (
                ''
              )}
            </Info>
          </>
        )}

        <ItemListHeader>
          <div>
            <p>Item</p>
          </div>
        </ItemListHeader>
        <MenuContent>
          <MenuItem>
            {cart.map(item => (
              <MenuItemList item={item} key={uuid()} />
            ))}
          </MenuItem>
        </MenuContent>
      </Content>
    </Container>
  );
};

export default Cart;
