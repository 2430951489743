import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from '../pages/Login';

import Menu from '../pages/Menu';
import Cart from '../pages/Cart';

import Product from '../pages/Product';
import ConfirmOrder from '../pages/ConfirmOrder';
import SearchAddress from '../pages/SearchAddress';
import SearchAddressByDistance from '../pages/SearchAddressByDistance';
import RestaurantRating from '../pages/RestaurantRating';
import AlmostThere from '../pages/AlmostThere';

import Goodbye from '../pages/Goodbye';
import Places from '../pages/Places';
import Payments from '../pages/Payments';
import Orders from '../pages/Orders';
import MyOrdersDetails from '../pages/MyOrdersDetails';
import InputCard from '../pages/InputCard';
import DeliveryType from '../pages/DeliveryType';

import { CartProvider } from '../context/cart';
import { OrderProvider } from '../context/order';
import { AuthProvider } from '../context/auth';
import { MenuProvider } from '../context/menu';

const Routes = () => {
  return (
    <Switch>
      <AuthProvider>
        <OrderProvider>
          <CartProvider>
            <MenuProvider>
            <Route path="/" exact component={Login} />

            <Route
              path="/:restaurant_name"
              isPrivate
              component={Places}
              exact
            />

            <Route path="/app/menu" isPrivate component={Menu}/>

            <Route path="/app/cart" isPrivate component={Cart} />

            <Route path="/app/deliverytype" isPrivate component={DeliveryType} />
            <Route path="/app/searchaddress" isPrivate component={SearchAddress} />
            <Route path="/app/searchaddressbydistance" isPrivate component={SearchAddressByDistance} />

            <Route path="/app/product" isPrivate component={Product} />
            <Route path="/app/confirmorder" isPrivate component={ConfirmOrder} />
            <Route path="/app/almostthere" isPrivate component={AlmostThere} />
            <Route path="/app/rating" isPrivate component={RestaurantRating} />

            <Route path="/app/payments" isPrivate component={Payments} />
            <Route path="/app/goodbye" isPrivate component={Goodbye} />
            <Route path="/app/orders" isPrivate component={Orders} />
            <Route path="/app/inputcard" isPrivate component={InputCard} />
            <Route
              path="/app/myordersdetails"
              isPrivate
              component={MyOrdersDetails}
            />
            </MenuProvider>
          </CartProvider>
        </OrderProvider>
      </AuthProvider>
    </Switch>
  );
};

export default Routes;
