import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #f8f8f8;
  width: 100%;
  max-width: 410px;

  /* display: flex;
  justify-content: center;
  align-items: center; */
`;

export const Content = styled.div`
  color: #333;

  min-height: 100vh;

  display: flex;
  flex-direction: column;

  align-items: center;
`;

export const Header = styled.header`
  position: fixed;
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;

  h1 {
    font-size: 18px;
  }
`;

export const ButtonOrders = styled.button`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 414px;
  height: 48px;
  background: #0aa5ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 7px 7px 0px 0px;

  svg {
    margin-right: 4px;
  }
`;

export const Main = styled.div`
  margin-top: 110px;
  width: 100%;

  padding-bottom: 55px;
`;

export const Nav = styled.nav`
  width: 100%;
  height: 40px;
  max-width: 410px;
  background: #fff;
  position: fixed;
  top: 60px;

  border-bottom: 1px solid #33333310;

  -webkit-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);

  display: flex;
  justify-content: center;
  padding-left: 24px;

  li {
    display: inline-flex;
    list-style: none;
    margin-right: 24px;
    color: #33333390;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;

    & + li {
      border-left: 1px solid #33333390;
      padding-left: 24px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;
