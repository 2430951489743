import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  background: #f8f8f8;
  width: 100%;
  max-width: 410px;

  /* display: flex;
  justify-content: center;
  align-items: center; */
`;

export const Content = styled.div`
  color: #333;

  min-height: 100vh;

  display: flex;
  flex-direction: column;

  align-items: center;
`;

export const Header = styled.header`
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;

  -webkit-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);
  box-shadow: 0px 1px 31px -20px rgba(120, 120, 120, 1);

  h1 {
    font-size: 18px;
  }
`;

export const Title = styled.div`
  padding: 10px;

  p {
    font-size: 14px;
    text-align: center;
  }
`;

export const ButtonOrders = styled.button`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 414px;
  height: 48px;
  background: #145e7e;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 7px 7px 0px 0px;

  svg {
    margin-right: 4px;
  }
`;

export const Orders = styled.div`
  width: 100%;
  padding: 10px;
  padding-bottom: 55px;
`;

export const EmptyOrders = styled.div`
  max-width: 410px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: 22px;
    text-align: center;

    span {
      font-size: 16px;
    }
  }

  img {
    width: 300px;
    height: 300px;
  }
`;
