import React from 'react';

import { Container, Title } from './styles';

const Goodbye = () => {
  return (
    <Container>
      <Title>
        <span>Obrigado por ter usado nosso sistema Garçom Digital ;D</span>
        <p>Equipe Takeat</p>
      </Title>
    </Container>
  );
};

export default Goodbye;
