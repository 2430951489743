import React, { useEffect, useState, useCallback } from 'react';

import api from '../../../services/api';

import Finished from './Finished';
import Canceled from './Canceled';
import Chargeback from './Chargeback';

import pedidoVazio from '../../../assets/images/pedidoVazio.svg';

import LoadingOrders from '../../../pages/LoadingOrders';

import ScrollToTop from '../../../components/ScrollToTop';

import { Container, Content, Orders, EmptyOrders } from './styles';

const OrdersFinished = () => {
  const [ordersFinished, setOrdersFinished] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await api.get('client/orders');

      const orders = response.data;

      const checkFinish = orders.filter(
        order => order.order_status === 'finished',
      );
      const checkCancel = orders.filter(
        order => order.order_status === 'canceled',
      );
      const checkCharge = orders.filter(
        order => order.order_status === 'chargeback',
      );

      setOrdersFinished([...checkCancel, ...checkFinish, ...checkCharge]);

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);

    fetchData();
  }, [fetchData]);

  return isLoading ? (
    <LoadingOrders />
  ) : (
    <Container>
      <ScrollToTop />

      <Content>
        <Orders>
          {ordersFinished
            .filter(order => order.order_status === 'finished')
            .map(order => (
              <Finished order={order} key={order.id} />
            ))}

          {ordersFinished
            .filter(order => order.order_status === 'canceled')
            .map(order => (
              <Canceled order={order} key={order.id} />
            ))}

          {ordersFinished
            .filter(order => order.order_status === 'chargeback')
            .map(order => (
              <Chargeback order={order} key={order.id} />
            ))}
        </Orders>
        {ordersFinished.length < 1 ? (
          <EmptyOrders>
            <h1>
              Ops!
              <br />
              <span>...não econtramos pedidos por aqui.</span>
            </h1>
            <img
              src={pedidoVazio}
              alt="ilustracao de um homem com uma lupa procurando um pedido"
            />
          </EmptyOrders>
        ) : (
          ''
        )}
      </Content>
    </Container>
  );
};

export default OrdersFinished;
