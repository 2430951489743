import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const OrderValue = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;

  p {
    font-weight: bold;
    color: #333;
  }
`;

export const Order = styled.div`
  width: 100%;
  min-height: 150px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  color: #333;
  border-radius: 7px;
  padding: 8px;
  text-decoration: none;
  -webkit-box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);
  -moz-box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);
  box-shadow: 0px 0px 21px -17px rgba(120, 120, 120, 1);

  & + div {
    margin-top: 16px;
  }
`;

export const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    font-weight: bold;
  }
`;

export const OrderBody = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;

  > div {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
  }
`;
export const OrderFooter = styled(Link)`
  text-decoration: none;
  margin-top: 16px;
  padding-top: 8px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #33333310;

  p {
    font-size: 14px;
    color: #333;
  }
`;

export const OrderStatus = styled.p`
  margin-top: 16px;
  font-size: 14px;
  color: #ff1814;
`;

export const ButtonsAction = styled.div`
  margin-top: 16px;
  display: flex;
`;

export const RemakePayment = styled.a`
  display: flex;
  align-items: center;
  padding: 4px;
  font-size: 12px;
  color: #fff;
  background: #4ceb5b;
  border: none;
  border-radius: 7px;
  text-decoration: none;

  p {
    margin-left: 2px;
  }
`;

export const CancelOrder = styled.button`
  display: flex;
  align-items: center;
  padding: 4px;
  font-size: 12px;
  color: #fff;
  background: #ff1814;
  border: none;
  border-radius: 7px;
  margin-left: 4px;

  p {
    margin-left: 2px;
  }
`;

export const ModalTitle = styled.div`
  p {
    text-align: left;
    font-weight: bold;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  margin-top: 16px;
  padding-top: 8px;
  border-top: 1px solid #33333310;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #4ceb5b;
  border: none;
  border-radius: 7px;
  margin-right: 8px;
`;

export const CancelButton = styled.button`
  display: flex;
  align-items: center;
  padding: 7px;
  font-size: 14px;
  color: #fff;
  background: #ff1814;
  border: none;
  border-radius: 7px;
  margin-left: 4px;
`;
