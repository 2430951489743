import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import QrReader from 'react-qr-reader';

import { Container, StyledLink } from './styles';

const Login = props => {
  const history = useHistory();
  const [qrcode, setQrcode] = useState();
  const [shoppingName, setShoppingName] = useState('');

  function handleScan(data) {
    if (data) {
      setQrcode(data);
    }
  }

  function handleSetShoppingName(e) {
    setShoppingName(e.target.value);
  }

  function handleError(err) {
    console.error(err);
    alert('Não foi encontrado dispositivo de câmera');
  }

  function handleNavigate() {
    if (!qrcode) {
      history.push(`/${shoppingName.toLocaleLowerCase()}`);
    } else {
      window.location.href = `${qrcode}`;
    }
  }

  return (
    <Container>
      <h1>
        Leia o QR Code informado, para preencher o campo automaticamente, ou
        digite o nome do Shopping informado. <br />
      </h1>

      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: 260 }}
      />

      <input
        placeholder="Nome do restaurante"
        value={qrcode}
        onChange={e => {
          handleSetShoppingName(e);
        }}
      />

      <StyledLink onClick={handleNavigate}>Acessar cardápio</StyledLink>
    </Container>
  );
};

export default Login;
