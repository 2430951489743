import React from 'react';

import { Container } from './styles';

const LoadingOrders = () => {
  return (
    <Container>
      <h1>Carregando pedidos ...</h1>
    </Container>
  );
};

export default LoadingOrders;
